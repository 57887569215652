<template>
    <div>
        <modal :name="modal_name" class="final-modal" :class="{'modalZindex':safariTrue,'modal-fix-width':loggedInUser.role == 'customer','select-addons-modal':loggedInUser.role != 'customer'}" transition="nice-modal-fade">
            <div class="v-modal-content">
                <div class="v-modal-header">
                    <span class="v-modal-dialog-title v-modal-title">Add /EDIT {{ variantDetails.product_name }} /VARIANT</span>
                    <span @click="incVariant()">
                        <i class="icon icon-close pointer text-white"></i>
                    </span>
                </div>
                <div class="v-modal-body pr-1" style="max-height: 330px;overflow-x: hidden;overflow-y: auto;">
                    <div class="v-modal-layout" style="padding-right: 10px;">
                        <div class="d-flex align-items-center justify-content-center" v-show="variantDetails.variantDetails != variantDetails.selected_quantity">
                            <button @click="addVariant()" class="btn btn-secondary btn-smm pointer text-white" type="button">+ Add New Variant</button>
                        </div>
                        <div v-for="variant in variantDetails.variants" :key="variant.variant_id" class="mt-5">
                            <div v-if="variant.quantity > 0">
                                <span class="d-flex justify-content-between">
                                    <div>
                                        <el-tooltip v-if="variant.product_type == 'Non-Veg'" content="Non-Veg" placement="top">
                                            <img src="https://cdn.digicollect.com/cdn/pos/images-new-theme/non-veg.svg" />
                                        </el-tooltip>
                                        <el-tooltip v-else-if="variant.product_type == 'Veg'" content="Veg" placement="top">
                                            <img src="https://cdn.digicollect.com/cdn/pos/images-new-theme/veg.svg" />
                                        </el-tooltip>
                                        <el-tooltip v-else-if="variant.product_type == 'Egg'" content="Egg" placement="top">
                                            <img src="https://cdn.digicollect.com/cdn/pos/images-new-theme/egg.svg" />
                                        </el-tooltip>
                                        <el-tooltip v-else-if="variant.product_type == 'Pescatarian'" content="Pescatarian" placement="top">
                                            <img style="width:16px;" src="https://cdn.digicollect.com/cdn/pos/images-new-theme/psec.svg" />
                                        </el-tooltip>
                                        <span class="font-weight-bold" :class="{'pl-2':variant.product_type != ''}">{{ variantDetails.product_name }}</span>
                                    </div>
                                    <div class="input-prepend-append d-flex pl-1 pr-1 align-items-center">
                                        <a class="btn-prepend pt-1 pointer" :disabled="variant.quantity < 1"  @click="decreaseQty(variant)">
                                            <img src="https://cdn.digicollect.com/cdn/pos/images-new-theme/qty-minus.svg" style="width:25px" />
                                        </a>
                                        <input style="border: 1px solid #808080 !important;height: 26px !important" readonly v-model.number="variant.quantity" onkeypress="return event.charCode >= 48 && event.charCode <= 57" type="number" class="form-control text-center add-custom-addon"/>
                                        <a class="btn-append pt-1 pointer" @click="increaseQty(variant)">
                                            <img src="https://cdn.digicollect.com/cdn/pos/images-new-theme/plus-qty.svg" style="width:25px" />
                                        </a>
                                    </div>
                                </span>
                                <div class="mt-1 font-weight-bold ml-2 mb-5">
                                    <span class="text-green">Variant: </span> {{variant.variant_name}}
                                </div>
                            </div>

                        </div>
                        <div class="text-center mt-5">
                            <button class="btn btn-checkout" @click="continueVariant()">Continue</button>
                        </div>
                    </div>
                </div>
            </div>
        </modal>
        <combo-variant v-if="isVariant" @updateSelectVariant="updateSelectVariant" :source="'increaseCombo'" modal_name="combo-variant" :productDetails="productDetails"></combo-variant>
    </div>
</template>
<script>
import Swal from 'sweetalert2'
import { SweetModal } from 'sweet-modal-vue'
const ComboVariant = () => import('./ComboVariant');
export default {
    props:['modal_name','variantDetails'],
    data(){
        return{
            safariTrue:false,
            productDetails:{
                product_name:'',
                _id:'',
                selected_quantity:0,
                variants:[],
                hasMultipleVariants:true,
                image:'',
                is_available:true
            },
            isVariant:false
        }
    },
    components:{
        SweetModal,
        ComboVariant
    },
    computed:{
        loggedInUser() {
            return this.$store.getters.loggedInUser;
        }
    },
    methods:{
        incVariant(){
            Swal.fire({
                title: 'Alert',
                text: 'Are you sure you Dont want to Update the Product',
                type: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Yes'
            })
            .then(result => {
                if (result.value) {
                    this.incVariantConfirm()
                }
            })
        },
        incVariantConfirm(){
            this.$modal.hide(this.modal_name);
            setTimeout(() => {
                this.$modal.show('select-combo')
            }, 500);
        },
        continueVariant(){
            const result = [];
            const map = new Map();
            for (const item of this.variantDetails.variants) {
                if(!map.has(item.variant_id)){
                    map.set(item.variant_id, true);    // set any value to Map
                    result.push({
                        variant_id: item.variant_id,
                        product_id: this.variantDetails.product_id,
                        quantity:item.quantity,
                        product_type:item.product_type,
                        variant_name:item.variant_name,
                        variant_quantity:item.variant_quantity,
                        alternative_id:item.alternative_id
                    });
                }
            }
            this.variantDetails.variants = result
            this.$modal.hide(this.modal_name);
            this.$emit('UpdateBasedOnMultiple',this.variantDetails)
            setTimeout(() => {
                this.$modal.show('select-combo')
            }, 500);
        },
        decreaseQty(variant){
            if(variant.quantity > 0){
                variant.quantity--
                if(this.variantDetails.selected_quantity > 0){
                    this.variantDetails.selected_quantity--
                }
            }
        },
        increaseQty(variant){
            if(this.variantDetails.selected_quantity < this.variantDetails.alternative_max_quantity){
                if(variant.quantity < variant.variant_quantity){
                    variant.quantity++
                    this.variantDetails.selected_quantity++
                }
            }else{
                this.$toasted.global.error('Alternative Max Quantity Exceeded');
            }
        },
        addVariant(){
            if(this.variantDetails.selected_quantity < this.variantDetails.alternative_max_quantity){
                this.productDetails ={
                    product_name:this.variantDetails.product_name,
                    _id:this.variantDetails.product_id,
                    selected_quantity:this.variantDetails.selected_quantity,
                    variants:this.variantDetails.variants,
                    alternative_id:this.variantDetails.alternative_id
                }
                this.isVariant = true
                this.$modal.hide(this.modal_name);
                setTimeout(() => {
                    this.$modal.show('combo-variant');
                }, 200);
            }else{
                this.$toasted.global.error('Cannot Add More Variants as Max Alternative Exceeded')
            }
        },
        updateSelectVariant(details){
            this.variantDetails.variants.forEach((v,vi)=>{
                if(v.variant_id == details.variant_id){
                    v.quantity = details.quantity
                }
            })
            if(this.variantDetails.selected_quantity < this.variantDetails.alternative_max_quantity){
                this.variantDetails.selected_quantity++
            }
        }
    },
    mounted(){
        this.safariTrue = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
    },
}
</script>
<style scoped>
.add-custom-addon {
    width: 75px !important;
    border-bottom: none !important;
    color: #00448b !important;
    margin-left: 4px !important;
    margin-right: 4px !important;
}
.newbg:nth-child(even){
    background: #e6e6e6 !important;
    padding: 20px 10px 1px 10px !important;
    border-radius: 12px !important;
}
</style>